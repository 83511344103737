// LoadingPage.js
import '../style/LoadingPage.css';

function LoadingPage() {
    return (
        <div className="loader-wrapper">
            <div className="pong-loader"></div>
        </div>
    );
}

export default LoadingPage;
