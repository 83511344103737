import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../style/Meme.css';
import missing from '../assets/symbol_questionmark.png';

const Meme = ({ chains = [], tokens = [], error = null }) => {
  const [form, setForm] = useState({ tokenName: '', network: '' });
  const [monitorForm, setMonitorForm] = useState({
    monitorTokenName: '',
    monitorNetwork: '',
    monitorBuys: '',
    monitorSells: '',
    monitorLiquidity: '',
  });
  const [monitoredTokens, setMonitoredTokens] = useState([]);
  const [currentAddresses, setCurrentAddresses] = useState(new Set());
  const [dashboardTokens, setDashboardTokens] = useState([]);
  const MAX_TOKENS = 50;

  useEffect(() => {
    fetchLatestData();
    const intervalId = setInterval(fetchLatestData, 10000); // 10 sec
    return () => clearInterval(intervalId);
  }, []);

  const fetchLatestData = async () => {
    try {
      // const response = await fetch('http://10.0.0.106:8080/api/latest_data');
      const response = await fetch('https://crypto-lusi-api-ttt63a7gja-ue.a.run.app/api/latest_data');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Fetched data:', data);
      updateDashboard(data);
      // const newTokens = data.filter(token => !currentAddresses.has(token.pair_address));
      // if (newTokens.length > 0) {
      //   newTokens.forEach(token => currentAddresses.add(token.pair_address));
      //   updateDashboard(newTokens);
      // }
    } catch (error) {
      console.error('Failed to fetch latest data', error);
    }
  };

  const updateDashboard = (newTokens) => {
    // newTokens.sort((a, b) => new Date(b.token_created_at) - new Date(a.token_created_at));
    newTokens.sort((a, b) => parseInt(a.token_age_sec) - parseInt(b.token_age_sec));
    console.log('New tokens to be added:', newTokens);
    // setDashboardTokens(prev => [...newTokens, ...prev].slice(0, MAX_TOKENS));
    setDashboardTokens(newTokens)
  };

  const handleFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleMonitorFormChange = (e) => {
    setMonitorForm({ ...monitorForm, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  const handleMonitorFormSubmit = (e) => {
    e.preventDefault();
    // Handle monitor form submission
  };

  const handleStartMonitoring = async () => {
    try {
      await fetch('/monitor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(monitorForm),
      });
      alert('Started Monitoring...');
      // Close modal
    } catch (error) {
      console.error('Failed to start monitoring', error);
    }
  };

  const handleClearMonitoring = async (tokenName) => {
    try {
      await fetch('/cancel_monitoring', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token_name: tokenName }),
      });
      // Update monitored tokens list
    } catch (error) {
      console.error('Failed to clear monitoring', error);
    }
  };

  return (
    <div className="container changa-font">
      <div className="custom-search">
        <form className="mt-3" onSubmit={handleFormSubmit}>
          <div className="form-row-container">
            <div className="form-group">
              <input type="text" className="input-form" id="tokenName" name="tokenName" value={form.tokenName} onChange={handleFormChange} required placeholder='Unavailable | You Can Try 😉' /> {/* placeholder='Token Name' /> */}
            </div>
            {/* <div className="form-group">
              <div className="select-form">
                <Select
                  id="network"
                  name="network"
                  value={chains.find(option => option.value === form.network)}
                  onChange={option => setForm({ ...form, network: option.value })}
                  options={chains.map(chain => ({ value: chain, label: chain.charAt(0).toUpperCase() + chain.slice(1) }))}
                  placeholder="Select a network"
                />
              </div>
            </div> */}
            {/* <button type="submit" className="main-button" style={{ maxHeight: '42.5px' }}>Search</button> */}
            <button onClick={() => window.open('https://bongo.cat/', '_blank')} className="main-button" style={{ maxHeight: '42.5px' }}>Search</button>
          </div>
        </form>
      </div>
      
      <div id="dashboard">
        {dashboardTokens.length > 0 ? (
          <div>
            {dashboardTokens.map(token => (
              <div key={token.pair_address} className="token-post fade-in">
                <img
                  className="img-button"
                  src={token.token_icon ? token.token_icon : missing}
                  data-chain-id={token.chain_id}
                  data-token-address={token.pair_address}
                  data-token-price={token.token_price_usd}
                  data-token-symbol={token.token_symbol}
                  alt={`${token.token_symbol} logo`}
                />
                <div className="token-name-symbol">
                  <span>
                    {token.token_name.length > 20 ? `${token.token_name.slice(0, 10)}...` : token.token_name} 
                    <span className="token-symbol" data-symbol={token.token_symbol}> (
                      {token.token_symbol.length > 10 ? `${token.token_symbol.slice(0, 10)}...` : token.token_symbol} 
                    ) </span> 
                    <span className="token-quote">{token.token_quote}</span>
                  </span>
                  <span className="token-date">{token.token_age}</span>
                </div>
                <div className="token-info-container">
                  <div className="token-info">
                    <div
                      className="token-address"
                      data-address={token.pair_address}
                      onClick={() =>
                        window.open(`https://dexscreener.com/${token.chain_id}/${token.pair_address}`, '_blank')
                      }
                    >
                      {token.pair_address.slice(0, 6)}...{token.pair_address.slice(-4)}&nbsp;<i className="fas fa-copy"></i>
                    </div>
                    <div className="token-price">Price: {token.token_price_usd}</div>
                    <div className="token-network">{token.chain_id}</div>
                    <div className="token-liquidity">Liq: {token.token_liquidity}</div>
                    {/* <div className="token-socials">
                      {token.socials.map(social => (
                        <span key={social.url}>
                          <a href={social.url} target="_blank" rel="noopener noreferrer">{social.type}</a>
                        </span>
                      ))}
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>No tokens in the dashboard.</div>
        )}
      </div>

      <div className="modal fade" id="monitorModal" tabIndex="-1" role="dialog" aria-labelledby="monitorModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content custom-modal-style">
            <div className="modal-header">
              <h5 className="modal-title" id="monitorModalLabel">Set Monitoring Options</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form id="monitorForm" onSubmit={handleMonitorFormSubmit}>
                <div className="form-group">
                  <label htmlFor="monitorTokenName">Token Name</label>
                  <input type="text" className="form-control" id="monitorTokenName" name="monitorTokenName" value={monitorForm.monitorTokenName} onChange={handleMonitorFormChange} required />
                </div>
                <div className="form-group">
                  <label htmlFor="monitorNetwork">Network</label>
                  <select className="form-control" id="monitorNetwork" name="monitorNetwork" value={monitorForm.monitorNetwork} onChange={handleMonitorFormChange}>
                    <option value="">All Networks</option>
                    {chains.map(chain => <option key={chain} value={chain}>{chain.charAt(0).toUpperCase() + chain.slice(1)}</option>)}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="monitorBuys">Min. buys</label>
                  <input type="number" className="form-control" id="monitorBuys" name="monitorBuys" value={monitorForm.monitorBuys} onChange={handleMonitorFormChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="monitorSells">Min. sells</label>
                  <input type="number" className="form-control" id="monitorSells" name="monitorSells" value={monitorForm.monitorSells} onChange={handleMonitorFormChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="monitorLiquidity">Min. Liquidity</label>
                  <input type="number" className="form-control" id="monitorLiquidity" name="monitorLiquidity" value={monitorForm.monitorLiquidity} onChange={handleMonitorFormChange} />
                </div>
              </form>
            </div>
            <div className="modal-footer horizontal-button">
              <button type="button" className="main-button" onClick={handleStartMonitoring}>Start</button>
              <button type="button" className="neutral-button" onClick={() => {/* Show monitored tokens logic */ }}>List</button>
              <button type="button" className="secondary-button" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="listMonitoredTokensModal" tabIndex="-1" role="dialog" aria-labelledby="listMonitoredTokensModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content custom-modal-style">
            <div className="modal-header">
              <h5 className="modal-title" id="listMonitoredTokensModalLabel">Monitored Tokens</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" id="monitoredTokensList">
              {monitoredTokens.map(token => (
                <div key={token.token} className="monitored-token-item">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ display: 'grid' }}>
                      <span><strong>Token: {token.token}</strong></span>
                      <span>Network: {token.options.network}</span>
                    </p>
                    <button className="secondary-button" onClick={() => handleClearMonitoring(token.token)}>Delete</button>
                  </div>
                  <p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                    <span>Min Buys: {token.options.min_buy}</span>
                    <span>Min Sells: {token.options.min_sell}</span>
                    <span>Min Liquidity: {token.options.min_liquidity}</span>
                  </p>
                </div>
              ))}
            </div>
            <div className="modal-footer">
              <button type="button" className="neutral-button" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Meme;
