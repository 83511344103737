import React, { useState, useEffect } from 'react';
import logoImage from '../assets/logo512.png';
import '../style/NavMenu.css';
import { getAuth, signOut, updateProfile, onAuthStateChanged } from 'firebase/auth';
import { TypeAnimation } from 'react-type-animation';

const NavMenu = () => {
    const auth = getAuth();
    const superUsername = "Admin" // Temp for now
    const [username, setUsername] = useState('');
    const [triggerAnimation, setTriggerAnimation] = useState(false);
    const CURSOR_CLASS_NAME = 'custom-type-animation-cursor';
    const emojis = [
        "🐂", "🤞", "☘", "🎰", "🚀", "✨", "🌕", "🔥", "💹",
        "💰", "🤑", "💎", "⛏", "🤖", "💸"
    ];

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUsername(user.displayName || superUsername);
                setTriggerAnimation(true);
            } else {
                setUsername('');
                setTriggerAnimation(false);
            }
        });
        return unsubscribe;
    }, [auth]);

    const handleSignOut = () => {
        signOut(auth) // Sign out the user
            .then(() => {
                console.log('User signed out');
            })
            .catch((error) => {
                console.error('Sign out error:', error);
            });
    };

    const handleUpdateUsername = () => {
        const user = auth.currentUser;
        const newUsername = prompt('Enter your username:');
        if (newUsername) {
            // Update user's display name in Firebase Authentication
            updateProfile(user, { displayName: newUsername })
                .then(() => {
                    console.log('Username updated successfully');
                    setUsername(newUsername);
                })
                .catch((error) => {
                    console.error('Error updating username:', error);
                });
        }
    };

    const getRandomEmoji = () => {
        const randomIndex = Math.floor(Math.random() * emojis.length);
        return emojis[randomIndex];
    };

    return (
        <nav className="custom-navbar">
            <div className="custom-navbar-brand">
                <img src={logoImage} alt="Crypto LUSI" className="navbar-logo" />
                {triggerAnimation && (
                    <TypeAnimation
                        sequence={[
                            2000, // Wait 2 seconds
                            'Welcome Back...',
                            500,
                            `${username.substring(0, parseInt(username.length/3))}`,
                            500,
                            `${username.substring(0, parseInt(username.length/4))}`,
                            500,
                            `${username.substring(0, parseInt(username.length/2))}`,
                            500,
                            `${username} ${getRandomEmoji()}`,
                            2000,
                            (el) => el.classList.remove(CURSOR_CLASS_NAME),
                        ]}
                        speed={40}
                        repeat={false}
                        cursor={false}
                        className={CURSOR_CLASS_NAME}
                    />
                )}
                <style global jsx>{`
                    .custom-type-animation-cursor::after {
                        content: '|';
                        animation: cursor 1.1s infinite step-start;
                    }
                    @keyframes cursor {
                        50% {
                        opacity: 0;
                        }
                    }
                `}</style>
            </div>
            <div className="navbar-menu">
                <div className="navbar-end">
                    {/* <a href="/" className="navbar-item">Home</a> */}
                    {/* <a href="/about" className="navbar-item">About</a> */}
                    {/* <button onClick={handleUpdateUsername} className="navbar-item button sign-out-button">Set Username</button> */}
                    <button onClick={handleSignOut} className="navbar-item button sign-out-button">Sign Out</button>
                </div>
            </div>
        </nav>
    );

};

export default NavMenu