import React, { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import Firebase authentication functions
import './App.css';

import MainApp from './components/MainPage';
import LoginPage from './components/LoginPage';
import firebaseConfig from './firebaseConfig';

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);
const auth = getAuth(firebaseApp); // Initialize Firebase authentication

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoggedIn(!!user); // Set loggedIn based on whether user exists
    });

    return () => unsubscribe(); // Cleanup function
  }, []);

  return (
    <div>
      {loggedIn ? <MainApp /> : <LoginPage auth={auth} setLoggedIn={setLoggedIn} />} {/* Pass setLoggedIn to LoginPage */}
    </div>
  );
}

export default App;
