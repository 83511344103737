import React, { useEffect, useState, useRef } from 'react';
import '../style/SentimentalPost.css';
import xLogo from '../assets/x512.png';
import logo512 from '../assets/logo512.png';
import axios from 'axios';

const SentimentalTable = () => {
    // const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [visibleData, setVisibleData] = useState([]);
    const [bullCounter, setBullCounter] = useState(0);
    const [bearCounter, setBearCounter] = useState(0);
    const [neutralCounter, setNeutralCounter] = useState(0);
    const [gradient, setGradient] = useState('');
    const [originLink, setOriginLink] = useState("");
    const [linkSource, setLinkSource] = useState(logo512);
    const [popupContent, setPopupContent] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [lookbackDays, setlookbackDays] = useState(1);
    const popupRef = useRef(null);
    const predefinedValues = [1, 2, 3];

    const calculateGradient = (bullCounter, neutralCounter, bearCounter) => {
        const bullColor = '#91b089'; // Pastel Green
        const neutralColor = '#ddd262'; // Golden Yellow
        const bearColor = '#BC4749'; // Pastel Red

        const total = bullCounter + neutralCounter + bearCounter;
        const bullRatio = bullCounter / total;
        const neutralRatio = neutralCounter / total;
        const bearRatio = bearCounter / total;

        const currentGradient = `linear-gradient(90deg in hsl, ${bullColor} ${bullRatio * 100}%, ${neutralColor} ${(bullRatio + neutralRatio) * 100}%, ${bearColor} ${(bullRatio + neutralRatio + bearRatio) * 100}%)`;

        setGradient(currentGradient);
    };

    const SentimentalPost = ({ data, onClick }) => {
        // console.log('Rendering SentimentalPost with data:', data); // Debugging
        let tokens = [];
        if (typeof data[7] === 'string' && data[7] !== "None") {
            try {
                // Replace single quotes with double quotes
                let validJsonString = data[7].replace(/'/g, '"');
            
                // Handle special case where data is like "[GME]"
                if (validJsonString.startsWith('[') && validJsonString.endsWith(']')) {
                    validJsonString = validJsonString.replace(/\[(\w+)\]/g, '["$1"]');
                }

                tokens = JSON.parse(validJsonString);
            } catch (e) {
                // console.error('Error parsing data[7]:', e);
            }
        } else if (Array.isArray(data[7])) {
            tokens = data[7];
        }

        return (
            <div className={`sentimental-post`} onClick={onClick}>
                <div className="post-meta">
                    <p className='username'>{data[9] !== "None" ? data[9] : "Anon"}</p>
                    <p className='datetime'>{data[6]}</p>
                </div>
                <div className="post-container">
                    <p className="post-content">{data[1]}</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <div className="post-sentiment" style={getSentimentGradient(data[3], data[4])}>
                        <p>{data[3]}</p>
                        <p>{data[4]}</p>
                    </div>
                    <div className='post-token-container'>
                        {tokens.map((item, index) => (
                            item.length <= 15 ? (
                                <a key={index} className="post-token" href={`https://dexscreener.com/search?q=${item}`} target="_blank" rel="noopener noreferrer">
                                    {item}
                                </a>
                            ) : null
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const getSentimentGradient = (sentiment, confidence) => {
        const confidenceValue = parseFloat(confidence.replace('%', ''));

        let gradientColor;
        switch (sentiment) {
            case "Bullish":
                gradientColor = `linear-gradient(to left, #91b089, #667b60 ${confidenceValue}%)`;
                break;
            case "Bearish":
                gradientColor = `linear-gradient(to left, #BC4749, #843233 ${confidenceValue}%)`;
                break;
            case "Neutral":
            default:
                gradientColor = `linear-gradient(to left, #ddd262, #9b9345 ${confidenceValue}%)`;
                break;
        }

        const textShadow = `0 0 0 ${gradientColor}`;

        return {
            color: "transparent",
            background: gradientColor,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textShadow: textShadow
        };
    };

    const fetchSentimentData = async (lookback) => {
        try {
            // const response = await axios.post('http://10.0.0.106:8080/api/sentimental', {
            const response = await axios.post('https://crypto-lusi-api-ttt63a7gja-ue.a.run.app/api/sentimental', {
            // const response = await axios.post('http://192.168.0.106:8080/api/sentimental-page', {
                lookback: lookback, // Days
            });
            const sentiData = response.data;

            let bull = 0;
            let bear = 0;
            let neut = 0;

            sentiData.forEach(data => {
                const options = {
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                };
                const GMTtime = new Date(data[6]).toLocaleString('en-US', options);
                data[6] = GMTtime;

                if (data[3] === "Bullish") bull++;
                else if (data[3] === "Bearish") bear++;
                else neut++;
            });

            setBullCounter(bull);
            setBearCounter(bear);
            setNeutralCounter(neut);
            calculateGradient(bull, neut, bear);

            // setLastUpdated(new Date());
            // setData(prevData => [...prevData, ...sentiData]);
            setAllData(prevData => {
                const newData = [...prevData, ...sentiData];
                setVisibleData(newData.slice(0, 150)); // Display only the first X posts
                return newData;
            });
        } catch (error) {
            console.error('Error fetching sentimental data:', error);
        }
    };

    useEffect(() => {
        fetchSentimentData(lookbackDays);
        const intervalId = setInterval(() => {
            fetchSentimentData(lookbackDays);
        }, 0.5 * 60 * 1000); // 30 Sec
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpen(false);
            }
        };

        if (isPopupOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isPopupOpen]);

    const handlePopup = (event) => {
        const selectedArray = event;
        const context = selectedArray[1];
        const origin = selectedArray[8];

        const match = origin.match(/:\/\/(?:www\.)?([^\.]+)/);
        const source = match ? match[1] : null;
        if (origin.length > 1 && source == "twitter") {
            setLinkSource(xLogo);
        } else {
            setLinkSource(logo512);
        }
        setPopupContent(context);
        setOriginLink(origin)
        setIsPopupOpen(true);
    };

    const handleSentiButt = async (event) => {
        console.log(popupContent, event)
        try {
            const response = await axios.post('https://crypto-lusi-api-ttt63a7gja-ue.a.run.app/api/senti-rl', {
                context: popupContent,
                type: event
            });
            console.log(response.data.response);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleLookbackChange = (value) => {
        setlookbackDays(value);
        fetchSentimentData(value);
        setDropdownVisible(false); // Hide dropdown after selection
    };

    const handleCustomLookbackChange = (e) => {
        const customValue = parseInt(e.target.value, 10);
        if (!isNaN(customValue)) {
            setlookbackDays(customValue);
            fetchSentimentData(customValue);
            setDropdownVisible(false); // Hide dropdown after selection
        }
    };


    return (
        <div className='sentimental-container'>
            {isPopupOpen && <div className="overlay" onClick={() => setIsPopupOpen(false)}></div>}
            <div
                className={`popup${isPopupOpen ? ' -active' : ''}`}
                ref={popupRef}
            >
                <div className='fade-up'>
                    {popupContent}
                    <div className='rl-data-container'>
                        <div className='rl-button-container'>
                            <button
                                className='rl-button bull'
                                style={{ background: "#91b089" }}
                                onClick={() => {
                                    handleSentiButt("bull");
                                }}
                            >BULLISH</button>
                            <button
                                className='rl-button neut'
                                style={{ background: "#ddd262" }}
                                onClick={() => {
                                    handleSentiButt("neut");
                                }}
                            >NEUTRAL</button>
                            <button
                                className='rl-button bear'
                                style={{ background: "#BC4749" }}
                                onClick={() => {
                                    handleSentiButt("bear");
                                }}
                            >BEARISH</button>
                            <a href={originLink} target="_blank" className="origin-link">
                                <img src={linkSource} alt="x.com" className="link-logo" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dropdown-lookback-container'>
                <button
                    className='senti-container'
                    style={{ background: gradient }} // Set the background style dynamically
                    // onClick={() => {fetchSentimentData(lookbackDays)}}
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                >
                    <p className='senti'>Bullish: {bullCounter}</p>
                    <p className='senti'>Neutral: {neutralCounter}</p>
                    <p className='senti'>Bearish: {bearCounter}</p>
                </button>
                <div className='dropdown-lookback'>
                    {predefinedValues.map(value => (
                        <div
                            key={value}
                            onClick={() => handleLookbackChange(value)}
                            className='dropdown-lookback-item'
                        >
                            [ {value} ] Day{value > 1 ? 's' : ''}
                        </div>
                    ))}
                    <div className='dropdown-lookback-item custom'>
                        Lookback: <input type='number' onBlur={handleCustomLookbackChange} />
                    </div>
                </div>
            </div>
            <div className='sentimental-table-container'>
                {/* {data.length === 0 && <p>No data available</p>} */}
                {/* {data.map((rowData, index) => ( */}
                {allData.length === 0 && <p>No data available</p>}
                {visibleData.map((rowData, index) => (
                    <SentimentalPost key={index} data={rowData} onClick={() => handlePopup(rowData)} />
                ))}
            </div>
        </div>
    );
};

export default SentimentalTable;
