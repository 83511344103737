import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../style/TransactionTable.css';

const theme = createTheme({
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 750,
            laptop: 1024,
            desktop: 1200,
        },
    },
});
const TransactionTable = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await axios.post('http://192.168.0.106:8080/api/trans-history', { 
                const response = await axios.post('https://crypto-lusi-api-ttt63a7gja-ue.a.run.app/api/trans-history', { 
                    token: 'BTCUSDT'
                });
                const transData = response.data;
                console.log(transData);
                transData.forEach(data => {
                    const options = {
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric'
                    };
                    const GMTtime = new Date(1715491597582).toLocaleString('en-US', options);
                    data[0] = GMTtime;
                });

                setTransactions(transData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        const interval = setInterval(fetchData, 15 * 1000); // Every 15 Sec
        return () => clearInterval(interval);
    }, []);

    return (
        <div className='transaction-container'>
            <div className='transaction-header'>
                {!isMobile && <div className='table-cell'>Date</div>}
                <div className='table-cell'>Market</div>
                <div className='table-cell'>Pred</div>
                <div className='table-cell'>Exec</div>
                <div className='table-cell'>Qty</div>
                <div className='table-cell'>PnL</div>
            </div>
            <div className='transaction-body'>
                {transactions.map((transaction, index) => (
                    <div className='transaction-row-container' key={index}>
                        <div className='row-content'>
                            {!isMobile && <div className='table-cell'>{transaction[0]}</div>}

                            <div className='table-cell'>{transaction[1].toFixed(2)}</div>
                            <div className='table-cell'>{transaction[2].toFixed(2)}</div>
                            <div className='table-cell'>{transaction[3].toFixed(2)}</div>
                            <div className='table-cell'>{transaction[5].toFixed(2)}</div>
                            <div className='table-cell'>
                                {
                                    (
                                        (transaction[3] - transaction[1])
                                        * transaction[5]
                                    ).toFixed(2)
                                }
                            </div>
                        </div>
                        {isMobile && <div className='datetime-mobile'>{transaction[0]}</div>}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TransactionTable;
