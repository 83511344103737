// https://tradingview.github.io/lightweight-charts/tutorials
import React, { useRef, useEffect, useState } from 'react';
import { createChart, CrosshairMode, LineStyle } from 'lightweight-charts';
import '../style/PredictionGraph.css';
import axios from 'axios';

const PredictionV2 = () => {
    const chartContainerRef = useRef();
    const legendContainerRef = useRef();
    const [actualData, setActualData] = useState([]);
    const [predData, setPredData] = useState([]);
    let chart = null;
    let tooltipRef = useRef(null); // Ref for tooltip element

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await axios.post('http://192.168.0.106:8080/api/data', {
                const response = await axios.post('https://crypto-lusi-api-ttt63a7gja-ue.a.run.app/api/data', { 
                    type: 'kline',
                    // token: 'BTC' // Add this logic in later 
                });
                const { kline_data, pred_data } = response.data;
                const formattedKlineData = kline_data.map(item => ({
                    value: parseFloat(item[5]), // Close
                    time: parseInt(item[1] / 1000) // Milli to Sec
                }));
                formattedKlineData.sort((a, b) => a.time - b.time);
                
                const formattedPredData = pred_data.map(item => ({
                    value: parseFloat(item[2]), // Pred Price
                    time: parseInt(item[0]) // Already in Sec
                }));
                formattedPredData.sort((a, b) => a.time - b.time);

                setActualData(formattedKlineData);
                setPredData(formattedPredData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        const interval = setInterval(fetchData, 15 * 1000); // Every 15 Sec
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (chart) {
                chart.resize(
                    chartContainerRef.current.clientWidth, 
                    chartContainerRef.current.clientHeight
                );
            }
        };

        if (chartContainerRef.current && actualData.length > 0 && predData.length > 0) {
            chart = createChart(chartContainerRef.current, {
                timeScale: {
                    timeVisible: true, 
                    secondsVisible: true,
                    tickMarkFormatter: (time, tickMarkType, locale) => {
                        return new Date(time * 1000).toLocaleTimeString(locale);
                    },
                    tickMarkTimeInterval: { seconds: 5 }
                }, layout: {
                    fontFamily: "'Roboto', sans-serif",
                    background: { color: '#292929' },
                    textColor: '#F0EBD8',
                }, grid: {
                    vertLines: { color: '#444' },
                    horzLines: { color: '#444' },
                },
        
            });

            chart.applyOptions({
                priceScale: {
                    borderColor: '#97E7E1',
                },
                timeScale: {
                    borderColor: '#97E7E1',
                    barSpacing: 25,
                },
                crosshair: {
                    mode: CrosshairMode.Normal, //freely
                    vertLine: {
                        width: 4,
                        color: '#C3BCDB44',
                        style: LineStyle.Solid,
                        labelBackgroundColor: '#97E7E1',
                    },
                    horzLine: {
                        color: '#97E7E1',
                        labelBackgroundColor: '#97E7E1',
                    },
                },            
            });

            const lineSeries1 = chart.addLineSeries({ color: '#A1C398' });
            lineSeries1.setData(actualData);

            const lineSeries2 = chart.addLineSeries({ color: '#BC4749' });
            lineSeries2.setData(predData);

            legendContainerRef.current.innerHTML = '';
            const legendItems = [
                { color: '#A1C398', text: 'Actual' },
                { color: '#BC4749', text: 'Predicted' }
            ];

            legendItems.forEach((item) => {
                const legendItem = document.createElement('div');
                legendItem.style.display = 'flex';
                legendItem.style.alignItems = 'center';
                legendItem.style.fontSize = '0.8rem'
                const colorBox = document.createElement('div');
                colorBox.style.width = '10px';
                colorBox.style.height = '10px';
                colorBox.style.backgroundColor = item.color;
                colorBox.style.marginRight = '5px';
                const legendText = document.createElement('span');
                legendText.textContent = item.text;
                legendItem.appendChild(colorBox);
                legendItem.appendChild(legendText);
                legendContainerRef.current.appendChild(legendItem);
            });

            window.addEventListener('resize', handleResize);

            // Create tooltip element
            tooltipRef.current = document.createElement('div');
            tooltipRef.current.id = 'tooltip';
            tooltipRef.current.style.display = 'none';
            tooltipRef.current.style.position = 'absolute';
            tooltipRef.current.style.zIndex = '1000';
            tooltipRef.current.style.backgroundColor = 'rgb(41, 41, 41, 0.8)';
            tooltipRef.current.style.border = '1px solid #F0EBD8';
            tooltipRef.current.style.textAlign = 'left';
            tooltipRef.current.style.fontSize = '0.8rem';
            tooltipRef.current.style.padding = '5px 10px';
            tooltipRef.current.style.borderRadius = '8px';
            tooltipRef.current.style.fontFamily = "'Roboto', sans-serif";
            chartContainerRef.current.appendChild(tooltipRef.current);

            // Add crosshair move event listener for tooltip
            chart.subscribeCrosshairMove((param) => {
                const container = chartContainerRef.current;
                const toolTip = tooltipRef.current;

                if (param.time) {
                    const dateStr = new Date(param.time * 1000).toLocaleString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric'
                    });
                    const trueData = param.seriesData.get(lineSeries1);
                    const predData = param.seriesData.get(lineSeries2);
                    const truePrice = trueData ? trueData.value.toFixed(2) : null;
                    const predPrice = predData ? predData.value.toFixed(2) : null;

                    if (truePrice !== null && predPrice !== null) {
                        toolTip.style.display = 'block';
                        toolTip.innerHTML = `
                            <div>True: $ ${truePrice}</div>
                            <div>Pred: $ ${predPrice}</div>
                            <div>${dateStr}</div>
                        `;

                        // Position tooltip next to cursor
                        const rect = container.getBoundingClientRect();
                        const left = param.point.x + rect.left + 10;
                        const top = param.point.y + rect.top - 85;
                        toolTip.style.left = left + 'px';
                        toolTip.style.top = top + 'px';

                    } else {
                        toolTip.style.display = 'none';
                    }
                } else {
                    toolTip.style.display = 'none';
                }
            });
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            
            if (chart) {
                chart.remove();
                chart = null;
            }

            // Remove tooltip element
            if (tooltipRef.current) {
                tooltipRef.current.remove();
                tooltipRef.current = null;
            }

            // if (legendContainerRef) {
            //     legendContainerRef.current.remove();
            //     legendContainerRef.current = null;
            // }
        };
    }, [actualData, predData]);

    return (
        <div className='prediction-container'>
            <h3><em>Throttled Performance</em></h3>
            <div 
                className='prediction-graph' 
                ref={chartContainerRef}
            ></div>
            <div
                className='legend-container'
                ref={legendContainerRef}
            ></div>
        </div>
    );
};

export default PredictionV2;
