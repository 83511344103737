// MainApp.js
import * as React from 'react';
import { useState, useEffect } from 'react'
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import '../App.css';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import NavMenu from './NavMenu';
import Chatbot from './Chatbot';
import SentimentalPost from './SentimentalPost';
import PredictionV2 from './PredictionV2';
import TransactionTable from './TransactionTable';
import LoadingPage from './LoadingPage';
import LoginPage from './LoginPage';
import Meme from './Meme';

const drawerBleeding = 65; // Location of the Drawer Handle
const StyledBox = styled('div')({
    backgroundColor: '#31363F',
});

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[500] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 16,
    left: 'calc(50% - 15px)',
}));

const theme = createTheme({
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 750,
            laptop: 1024,
            desktop: 1200,
        },
    },
});

function MainApp() {
    const [isLoggedIn, setIsLoggedIn] = useState(null); // null to indicate loading state
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
    const [open, setOpen] = React.useState(false);
    const auth = getAuth();

    const [tabIndex, setTabIndex] = useState(0);
    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const [isChatWindowVisible, setIsChatWindowVisible] = useState(false);
    const toggleChatWindow = () => {
        setIsChatWindowVisible(!isChatWindowVisible);
    };

    useEffect(() => {
        let inactivityTimer;

        const resetTimer = () => {
            clearTimeout(inactivityTimer);
            inactivityTimer = setTimeout(handleSignOut, (6 * 60 * 60 * 1000)); // 6 hours
        };

        const handleActivity = () => {
            resetTimer();
        };

        const handleSignOut = () => {
            signOut(auth)
                .then(() => {
                    setIsLoggedIn(false);
                    console.log('User signed out due to inactivity');
                })
                .catch((error) => {
                    console.error('Sign out error:', error);
                });
        };

        resetTimer();
        document.addEventListener('mousemove', handleActivity);
        document.addEventListener('keydown', handleActivity);

        return () => {
            clearTimeout(inactivityTimer);
            document.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('keydown', handleActivity);
        };
    }, [auth]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        });

        return () => unsubscribe();
    }, [auth]);

    if (isLoggedIn === null) {
        return <LoadingPage />;
    }

    if (!isLoggedIn) {
        return <LoginPage auth={auth} setLoggedIn={setIsLoggedIn} />;
    }

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return (
        <div className='App'>
            <CssBaseline />
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        backgroundColor: '#31363F',
                        height: `calc(100%)`, // Adjusted for full screen height
                        overflow: 'visible',
                    },
                }}
            />
            <NavMenu />
            <Box className='tab-container'>
                <Tabs
                    value={tabIndex}
                    onChange={handleChange}
                    variant='fullWidth'
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#89a7c2', // Change the color of the indicator here
                        },
                        '& .MuiTab-root': {
                            '&:hover': {
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px'
                            },
                            fontSize: '1rem'
                        }
                    }}
                >
                    <Tab label="Posts" sx={{
                        color: "#F0EBD8",
                        '&.Mui-selected': {
                            color: '#89a7c2',
                        },
                        '&:hover': {
                            backgroundColor: '#535760',
                        },
                        flexGrow: 1
                    }} />
                    <Tab label="Market" sx={{
                        color: "#F0EBD8",
                        '&.Mui-selected': {
                            color: '#89a7c2',
                        },
                        '&:hover': {
                            backgroundColor: '#535760',
                        },
                        flexGrow: 1
                    }} />
                    <Tab label="CAT" sx={{
                        color: "#F0EBD8",
                        '&.Mui-selected': {
                            color: '#89a7c2',
                        },
                        '&:hover': {
                            backgroundColor: '#535760',
                        },
                        flexGrow: 1
                    }} />
                    <Tab label="Meme" sx={{
                        color: "#F0EBD8",
                        '&.Mui-selected': {
                            color: '#89a7c2',
                        },
                        '&:hover': {
                            backgroundColor: '#535760',
                        },
                        flexGrow: 1
                    }} />
                </Tabs>
            </Box>
            {isMobile ? (
                <div className='mobile-container'>

                    {tabIndex === 0 && (
                        <SentimentalPost />
                    )}
                    {tabIndex === 1 && (
                        <></>
                    )}
                    {tabIndex === 2 && (
                        <>
                            <PredictionV2 />
                        </>
                    )}
                    {tabIndex === 3 && (
                        <>
                            <Meme />
                        </>
                    )}
                    <SwipeableDrawer
                        anchor="bottom"
                        open={open}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                        swipeAreaWidth={drawerBleeding}
                        disableSwipeToOpen={false}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        <StyledBox
                            sx={{
                                position: 'absolute',
                                top: -drawerBleeding,
                                borderTopLeftRadius: 8,
                                borderTopRightRadius: 8,
                                visibility: 'visible',
                                right: 0,
                                left: 0,
                            }}
                        >
                            <Puller />
                        </StyledBox>
                        <div className='drawer-container'>
                            {tabIndex === 0 && <Chatbot />}
                            {tabIndex === 1 && <TransactionTable />}
                        </div>
                    </SwipeableDrawer>
                </div>
            ) : (
                // Desktop View
                <div className='desktop-container'>
                    <div className={
                        tabIndex === 0 ? `left-senti-section ${isChatWindowVisible ? '' : 'expanded'}` :
                            tabIndex === 1 ? 'left-pred-section' :
                                'left-charts-section'
                    }>
                        {tabIndex === 0 && (
                            <SentimentalPost />
                        )}
                        {tabIndex === 1 && (
                            <></>
                        )}
                        {tabIndex === 2 && (
                            <div className='pred-section'>
                                <TransactionTable />
                            </div>
                        )}

                    </div>
                    <div className={
                        tabIndex === 0 ? `right-senti-section ${isChatWindowVisible ? '' : 'collapsed'}` :
                            tabIndex === 1 ? 'right-pred-section' :
                                'right-charts-section'
                    }>
                        {tabIndex === 0 && (
                            <>
                                <button className='activate-chatai-button' onClick={toggleChatWindow}></button>
                                <Chatbot isChatWindowVisible={isChatWindowVisible} />
                            </>
                        )}
                        {tabIndex === 2 && (
                            <></>
                        )}

                    </div>
                    <div className='full-section'>                        
                        {tabIndex === 3 && (
                            <Meme />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default MainApp;
