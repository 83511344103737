import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesUp, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import '../style/Chatbot.css';

const Chatbot = ({ isChatWindowVisible }) => {
    const [inputMessage, setInputMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [placeholder, setPlaceholder] = useState('Message LUSI...');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const inputRef = useRef(null);
    const chatHistoryRef = useRef(null);

    function toMarkdown(text) {
        text = text.replace(/•/g, ' *');
        return text.split('\n').join('\n ');
    }

    const handleInputChange = (event) => {
        setInputMessage(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        if (inputMessage.trim() === '' || isLoading) return;
        setDisableSubmit(true);
        try {
            const timestamp = new Date().toLocaleString();
            setChatHistory([...chatHistory, { user: inputMessage, bot: isLoading ? '...' : '', timestamp: timestamp }]);
            setInputMessage('');
            setPlaceholder('Message LUSI...');
            setIsLoading(true);
            // NEED to update this according to the local-backend subdomain
            const response = await axios.post('https://db85-2601-249-8380-4d80-6102-8e2d-a33c-c19a.ngrok-free.app/api/chat', { message: inputMessage });
            // const response = await axios.post('mature-thrush-pleased.ngrok-free.app/api/chat', { message: inputMessage });
            // const response = await axios.post('https://crypto-lusi-api-ttt63a7gja-ue.a.run.app/api/chat', { message: inputMessage });
            // const response = await axios.post('http://192.168.0.106:8080/api/chat', { message: inputMessage });
            const botResponse = response.data.response;
            if (botResponse.trim() === '') {
                setChatHistory(prevChatHistory => [...prevChatHistory.slice(0, -1), { user: inputMessage, bot: "这就尴尬了... I'm taking a nap.\n\nPlease Try Again Later. 😴", timestamp: timestamp }]);
            } else {
                setChatHistory(prevChatHistory => [...prevChatHistory.slice(0, -1), { user: inputMessage, bot: botResponse, timestamp: timestamp }]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsLoading(false);
            setDisableSubmit(false);
        }
    };

    const handleClear = async () => {
        try {
            setChatHistory([]);
        } catch (error) {
            console.error('Error Clearing History:', error);
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);

    const scrollToBottom = () => {
        if (chatHistoryRef.current) {
            chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        const textareaWidth = inputRef.current.offsetWidth;
        const averageCharWidth = 9;
        const charsPerRow = Math.floor(textareaWidth / averageCharWidth);
        const numRows = Math.ceil((inputMessage.length + 1) / charsPerRow);
        inputRef.current.rows = numRows;
    }, [inputMessage]);

    return (
        <div className="chatbot-container">  
            <div className='activate-chatai-container'></div>          
            <div className={`chat-window ${isChatWindowVisible ? '' : 'hidden'}`}>
                <div className="chat-history" ref={chatHistoryRef}>
                    {chatHistory.map((chat, index) => (
                        <div key={index} className="chat-message">
                            <div className="user-message">{chat.user}</div>
                            {chat.bot && (
                                <div>
                                    <div className="bot-message">
                                        <ReactMarkdown>{toMarkdown(chat.bot)}</ReactMarkdown>
                                    </div>
                                    <div className="timestamp">{chat.timestamp}</div>
                                </div>
                            )}
                        </div>
                    ))}
                    {isLoading && <div className="chat-message"><div className="bot-message loading-message"></div></div>}
                </div>
                <div className="message-input-container">
                    <textarea
                        className="message-input"
                        value={inputMessage}
                        onChange={handleInputChange}
                        placeholder={placeholder}
                        onKeyDown={handleKeyDown}
                        onFocus={() => {
                            if (placeholder === 'Message LUSI...') {
                                setPlaceholder('');
                            }
                        }}
                        onBlur={() => {
                            if (!inputMessage.trim()) {
                                setPlaceholder('Message LUSI...');
                            }
                        }}
                        ref={inputRef}
                    />
                    <div className='button-container'>
                        <button type="button" className="send-button" onClick={handleSubmit} disabled={disableSubmit}><FontAwesomeIcon icon={faAnglesUp} /></button>
                        <button type="button" className="clear-button" onClick={handleClear}><FontAwesomeIcon icon={faTrashCan} /></button>
                    </div>
                </div>
            </div>
        </div>
    );    
};

export default Chatbot;
