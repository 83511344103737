// LoginPage.js
import React, { useState } from 'react';
import {
  signInWithEmailAndPassword, signInWithPopup,
  GoogleAuthProvider, signOut
} from "firebase/auth";
import '../style/LoginPage.css';
import logoImage from '../assets/logo512.png';
import googleIcon from '../assets/google-icon.png';
import LoadingPage from './LoadingPage';

function LoginPage({ auth, setLoggedIn }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordLength, setPasswordLength] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const provider = new GoogleAuthProvider();

  const handleEmailLogin = () => {
    if (!email.includes('@') || !email.includes('.')) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    setIsLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log("User logged in:", user);
        setLoggedIn(true);
        setErrorMessage('');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setErrorMessage("Invalid Email or Password");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGoogleSignIn = async () => {
    setIsLoading(true);  
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;  
      if (user.email.endsWith('@catasphere.io')) {
        setLoggedIn(true);
        setErrorMessage(null);
      } else {
        setErrorMessage('Please use your organization email (catasphere.io).');
        await signOut(auth);
        console.log("User signed out due to invalid domain");
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      setErrorMessage("Google Sign-In failed");
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleEmailLogin();
    }
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordLength(newPassword.length);
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="login-container">
      <div className="logo-container">
        <div className={`logo ${errorMessage ? 'logo-error' : ''}`}>
          <img src={logoImage} alt="Crypto LUSI" className="login-logo" />
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
      <form>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            onKeyDown={handleKeyDown}
          />
        </div>
        <button
          type="button"
          className={passwordLength >= 8 ? 'login-button-active' : 'login-button'}
          onClick={handleEmailLogin}
        >Login</button>
      </form>
      <button
        type="button"
        className="google-signin-button"
        onClick={handleGoogleSignIn}
      >
        <img src={googleIcon} alt="Google Icon" />
        Sign in with Google
      </button>
    </div>
  );
}

export default LoginPage;
