// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBZCJ_fBqZV0JCVoLlUCeb3veZijI32rUo",
  authDomain: "cryptolusi-1b6ff.firebaseapp.com",
  projectId: "cryptolusi-1b6ff",
  storageBucket: "cryptolusi-1b6ff.appspot.com",
  messagingSenderId: "62394768861",
  appId: "1:62394768861:web:1144c212a1bbc2e5872a34",
  measurementId: "G-4DD952C9H3"
};

export default firebaseConfig;